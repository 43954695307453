.header {
  max-width: 1000px;
  margin: auto;
}

.header__items {
  display: flex;
  justify-content: space-between;

  nav {
    a {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
