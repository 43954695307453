.books {
  background-color: $books;
  margin: 0;
  padding: 25px 50px;

  .books__container {
    padding: 100px 0 60px;
    max-width: 1000px;
    margin: auto;
  }

  .books__article {
    max-width: 700px;
    line-height: 1.8em;
    font-size: 1em;
  }

  .books__grid {
    justify-content: center;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
    column-gap: 3%;
    row-gap: 46px;
    grid-template-rows: auto;
    grid-template-areas:
      'a a a a'
      'b b b b'
      'c c c c';
    margin-bottom: 60px;
    a {
      align-self: end;
      justify-self: center;
    }
  }

  .book {
    display: block;
    img {
      width: 100%;
      min-width: 200px;
      max-width: 250px;
    }
  }

  @media screen and (max-width: 1024px) {
    .books__grid {
      grid-template-areas:
        'a a a'
        'b b b'
        'c c c';
      grid-template-columns: repeat(auto-fit, minmax(200px, 220px));
    }
  }

  @media screen and (max-width: 700px) {
    padding: 25px;
    .books__grid {
      grid-template-areas:
        'a a'
        'b b'
        'c c'
        'd d'
        'e e';
      grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
    }

    .book {
      img {
        min-width: 150px;
        max-width: 200px;
      }
    }
  }
}
