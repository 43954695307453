.index {
  background-color: $index;
  margin: 0;
  padding: 25px 50px;

  .index__main {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 100px;
    padding-bottom: 100px;
  }

  .main__figure {
    margin: 0;
    padding: 0;
  }

  .main__article {
    max-width: 510px;
    line-height: 1.8em;
    font-size: 1em;
    align-self: center;
  }

  @media screen and (max-width: 1024px) {
    .main__figure {
      img {
        max-width: 320px;
      }
    }
    .main__article {
      padding-left: 33px;
    }
  }

  @media screen and (max-width: 768px) {
    .index__main {
      flex-direction: column;
      margin-top: 60px;
    }

    .main__article {
      padding: 0;
      align-self: flex-start;
    }
  }
}
