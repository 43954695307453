@font-face {
  font-family: iAWriter;
  src: url("iAWriterQuattroS-Regular.5e5afd92.woff2");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: iAWriter;
  src: url("iAWriterQuattroS-Bold.5a5591f3.woff2");
  font-style: normal;
  font-weight: bold;
}

a {
  color: inherit;
}

.header {
  max-width: 1000px;
  margin: auto;
}

.header__items {
  justify-content: space-between;
  display: flex;
}

.header__items nav a {
  cursor: pointer;
  text-decoration: none;
}

.index {
  background-color: #efede0;
  margin: 0;
  padding: 25px 50px;
}

.index .index__main {
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 100px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
}

.index .main__figure {
  margin: 0;
  padding: 0;
}

.index .main__article {
  max-width: 510px;
  align-self: center;
  font-size: 1em;
  line-height: 1.8em;
}

@media screen and (max-width: 1024px) {
  .index .main__figure img {
    max-width: 320px;
  }

  .index .main__article {
    padding-left: 33px;
  }
}

@media screen and (max-width: 768px) {
  .index .index__main {
    flex-direction: column;
    margin-top: 60px;
  }

  .index .main__article {
    align-self: flex-start;
    padding: 0;
  }
}

.books {
  background-color: #fff0bc;
  margin: 0;
  padding: 25px 50px;
}

.books .books__container {
  max-width: 1000px;
  margin: auto;
  padding: 100px 0 60px;
}

.books .books__article {
  max-width: 700px;
  font-size: 1em;
  line-height: 1.8em;
}

.books .books__grid {
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  grid-template-areas: "a a a a"
                       "b b b b"
                       "c c c c";
  justify-content: center;
  gap: 46px 3%;
  margin: auto auto 60px;
  display: grid;
}

.books .books__grid a {
  place-self: end center;
}

.books .book {
  display: block;
}

.books .book img {
  width: 100%;
  min-width: 200px;
  max-width: 250px;
}

@media screen and (max-width: 1024px) {
  .books .books__grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 220px));
    grid-template-areas: "a a a"
                         "b b b"
                         "c c c";
  }
}

@media screen and (max-width: 700px) {
  .books {
    padding: 25px;
  }

  .books .books__grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
    grid-template-areas: "a a"
                         "b b"
                         "c c"
                         "d d"
                         "e e";
  }

  .books .book img {
    min-width: 150px;
    max-width: 200px;
  }
}

.footer .footer__container {
  max-width: 1000px;
  margin: auto;
}

.footer a {
  cursor: pointer;
  text-decoration: underline;
}

body {
  color: #000000b3;
  margin: 0;
  padding: 0;
  font-family: iAWriter, serif;
}

section {
  margin: 0;
  padding: 0;
}

.wrapper {
  max-width: 1100px;
  margin: auto;
}

/*# sourceMappingURL=books.56602a6e.css.map */
